
import { Component } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Mutation, Getter } from "vuex-class";
import StockList from "@/components/user/list/StockList.vue";
import { IProductOrderStatusListRequest } from "@/types/product_order_status";
import { ISupplierListRequest } from "@/types/supplier";
import { IMonthlyCountStockListRequest } from "@/types/monthly_count_stock";
import { IOrder } from "@/types/order";
import { IReceivedOrder } from "@/types/received_order";
import { IProduct } from "@/types/product";
@Component({
  components: { StockList }
})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  // product
  @Action("product_order_status/userGetProductOrderStatus")
  public getProductOrderStatus!: (
    params: IProductOrderStatusListRequest
  ) => Promise<boolean>;

  @Action("product/userGet")
  public getProduct!: (product_id: number) => Promise<boolean>;

  @Getter("product/single")
  public product!: IProduct;

  @Getter("product_order_status/order_product")
  public orders!: [];

  @Getter("product_order_status/product_received_order")
  public received_orders!: [];

  @Mutation("product_order_status/clear")
  public clearProductOrderStatus!: () => void;

  @Mutation("product/clear")
  public clearProduct!: () => void;

  // supplier
  @Action("supplier/userGetList")
  public getSupplierList!: (
    supplierParams: ISupplierListRequest
  ) => Promise<boolean>;

  @Getter("supplier/list")
  public suppliers!: [];

  @Mutation("supplier/clear")
  public clearSupplier!: () => void;

  // monthly_count_stock
  @Action("monthly_count_stock/userGetMonthlyCountStock")
  public getMonthlyCountStock!: (
    monthlyParams: IMonthlyCountStockListRequest
  ) => Promise<boolean>;

  @Action("monthly_count_stock/userGetMonthlyCountCalculation")
  public getMonthlyCountCalc!: (
    monthlyParams: IMonthlyCountStockListRequest
  ) => Promise<boolean>;

  @Getter("monthly_count_stock/storing_sum")
  public storing_sum!: number;

  @Getter("monthly_count_stock/shipment_sum")
  public shipment_sum!: number;

  @Getter("monthly_count_stock/warehouse_stock_array")
  public stock_by_warehouse!: [];

  @Mutation("monthly_count_stock/clear")
  public clearCount!: () => void;

  // fields
  public params: IProductOrderStatusListRequest = {};
  public supplierParams: ISupplierListRequest = {};
  public monthlyParams: IMonthlyCountStockListRequest = {};
  public menu = false;
  public selectDate = "";
  public selected = [];
  public date_disp = {
    year: "",
    month: ""
  };

  public async created() {
    this.params.product_id = this.monthlyParams.product_id = Number(
      this.$route.params.product_id
    );
    await this.this_month_insert();
    this.clearProductOrderStatus();
    this.clearProduct();
    this.clearSupplier();
    this.clearCount();

    await Promise.all([
      this.getProductOrderStatus(this.params),
      this.getSupplierList(this.supplierParams),
      this.getMonthlyCountStock(this.monthlyParams),
      this.getProduct(this.params.product_id)
    ]);

    this.this_month_insert();
  }

  public this_month_insert() {
    const today = new Date();
    const start_date = new Date(today.getFullYear(), today.getMonth(), 1);
    const fixed_start_date =
      start_date.getFullYear() +
      "-" +
      ("0" + (start_date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + start_date.getDate()).slice(-2);

    const end_date = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const fixed_end_date =
      end_date.getFullYear() +
      "-" +
      ("0" + (end_date.getMonth() + 1)).slice(-2) +
      "-" +
      end_date.getDate();

    this.monthlyParams.start_date = fixed_start_date;
    this.monthlyParams.search_span_end = fixed_end_date;
    this.selectDate =
      today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2);
    this.date_disp.year = String(today.getFullYear());
    this.date_disp.month = String(("0" + (today.getMonth() + 1)).slice(-2));
  }

  public setFixedDate() {
    if (this.selectDate) {
      this.monthlyParams.start_date = "";
      this.monthlyParams.search_span_end = "";
    }
    const select_date = new Date(this.selectDate);
    const start_date = new Date(
      select_date.getFullYear(),
      select_date.getMonth(),
      1
    );
    const fixed_start_date =
      start_date.getFullYear() +
      "-" +
      ("0" + (start_date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + start_date.getDate()).slice(-2);

    const end_date = new Date(
      select_date.getFullYear(),
      select_date.getMonth() + 1,
      0
    );
    const fixed_end_date =
      end_date.getFullYear() +
      "-" +
      ("0" + (end_date.getMonth() + 1)).slice(-2) +
      "-" +
      end_date.getDate();

    this.monthlyParams.start_date = fixed_start_date;
    this.monthlyParams.search_span_end = fixed_end_date;
    this.date_disp.year = String(select_date.getFullYear());
    this.date_disp.month = String(
      ("0" + (select_date.getMonth() + 1)).slice(-2)
    );

    // 選択された月が一年以内かそれ以上かでリクエスト先が違う
    const one_year_ago = new Date();
    const last_day = new Date(
      one_year_ago.getFullYear() - 1,
      one_year_ago.getMonth() + 1,
      0
    );

    if (end_date < last_day) {
      this.getMonthlyCountCalc(this.monthlyParams);
    } else {
      this.getMonthlyCountStock(this.monthlyParams);
    }
  }

  public received_order_click(id: number) {
    this.$router.push(`/user/received_order/${id}`);
  }

  public order_click(id: number) {
    this.$router.push(`/user/order/${id}`);
  }

  public sum_received_order_quantity(received_order: IReceivedOrder) {
    let sum_quantity = 0;
    received_order.products.forEach(product => {
      if (this.params.product_id == product.product_id) {
        sum_quantity += product.quantity || 0;
      }
    });
    return sum_quantity;
  }

  public sum_order_quantity(order: IOrder) {
    let sum_quantity = 0;
    order.products.forEach(product => {
      if (this.params.product_id == product.product_id) {
        sum_quantity += product.quantity;
      }
    });
    return sum_quantity;
  }

  public headers = [
    { text: "倉庫名", value: "name" },
    { text: "前月繰越数", value: "carried_forward", align: "end" },
    { text: "入荷数", value: "storing_total", align: "end" },
    { text: "倉庫入荷数", value: "transfer_in", align: "end" },
    { text: "倉庫出荷数", value: "transfer_out", align: "end" },
    { text: "出荷数", value: "shipment_total", align: "end" },
    { text: "指定月在庫数", value: "current_quantity", align: "end" }
  ];
}
